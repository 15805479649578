import { useState } from 'react'

// TODO: this probably exists in triniti-product, reuse what they have there
export function CopyAudioUrl({ audioUrl }: { audioUrl: string }) {
  const [ copied, setCopied ] = useState(false)

  const copyToClipboard = async () => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        // navigator.clipboard is supported and page is served over HTTPS
        await navigator.clipboard.writeText(audioUrl)
      } else {
        // Fallback method
        const textArea = document.createElement('textarea')
        textArea.value = audioUrl
        textArea.style.position = 'fixed' // Prevent scrolling to bottom of page in MS Edge
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        document.execCommand('copy')
        document.body.removeChild(textArea)
      }
      setCopied(true)
      setTimeout(() => setCopied(false), 2000) // Reset the copied state after 2 seconds
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  return (
    <div>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault()
          copyToClipboard()
        }}
        className="text-blue-500 hover:underline"
      >
        Copy audio URL to clipboard
      </a>
      {copied && <span className="text-green-500 ml-2">Copied!</span>}
    </div>
  )
}

export default CopyAudioUrl
